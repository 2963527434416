@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.head-pic {
	//background-image: url("../images/main_header.jpg")
	background-image: url("../images/novinky_s.jpg");
	background-size: cover;
	background-position: center;
	height: 470px;
	width: 100%;
	.head-rectangle {
		//border: 2px solid
		//border-image: linear-gradient(90deg, $dark_2 1%, $light_1 100%)
		//border-image-slice: 1
		font-family: $font1;
		padding: 20px 20px;
		margin-top: 95px;
		@media (max-width: 991px) {
			margin-top: 10px; } }
	h1 {
		color: $header_main_color;
		font-size: 52px;
		font-weight: 700;
		margin: 0; }
	h2 {
		color: $text_font;
		font-size: 25px;
		font-weight: 300; }
	.head-controls {
		margin-top: 10px;
 }		//text-align: right
	.head-subpage {
		margin-top: 125px; } }

.subpage {
	height: 370px;
	&:after {
		display: block;
		content: "";
		//background-color: rgba(255,255,255,0.6)
		width: 100%;
		height: 100%; } }

.sluzba {
	height: 180px;
	/* 100% / 6 sluzieb */
	//width: 16.6% !important
	margin-right: 0px !important;
	color: $white;
	font-size: 28px;
	font-family: $font1;
	font-weight: 700;
	text-align: center;
	opacity: 1;
	line-height: 35px;
	-webkit-transform: scale(0);
	transform: scale(0);
	-webkit-animation: startanimation 0.3s ease-in 0s 1 forwards;
	animation: startanimation 0.3s ease-in 0s 1 forwards;
	&:hover {
		opacity: 0.50; }
	span {
		margin-top: 20px;
		margin-bottom: 20px;
		display: block; } }
.ram {
	height: 90%;
	margin: 10px;
	border: 1px solid transparent; }
.sluzby_typ1 {
	background-image: linear-gradient($angle_main, $light_1 1%, $dark_1 100%);
	.sluzby-btn {
		&:hover {
			color: $light_1; } }
	&:hover {
		.ram {
			border: 1px solid;
			border-image: linear-gradient(90deg, $dark_1 1%, $light_1 100%);
			border-image-slice: 1; } } }
.sluzby_typ2 {
	background-image: linear-gradient($angle_main, $light_2 1%, $dark_2 100%);
	.sluzby-btn {
		&:hover {
			color: $light_2; } }
	&:hover {
		.ram {
			border: 1px solid;
			border-image: linear-gradient(90deg, $dark_2 1%, $light_2 100%);
			border-image-slice: 1; } } }
.sluzby-btn {
	background-color: transparent;
	color: $white;
	font-weight: 400;
	font-size: 18px;
	border: 1px solid $white;
	width: 110px;
	height: 45px;
	padding-top: 8px;
	display: block;
	margin: 0 auto;
	line-height: 25px;
	position: absolute;
	bottom: 0;
	transform: translate(-50%, -50%);
	left: 50%;
	&:hover {
		background-color: white;
		text-decoration: none; } }

/* NEWS PART */

.news {
	font-family: $font1; }
.news-sub {
	font-family: $font1;
	color: $text_font;
	font-size: 14px;
	text-align: center;
	font-weight: 200;
	line-height: 16px; }
.news-item {
	border: 1px solid transparent;
	padding-top: 15px;
	padding-bottom: 15px;
	&:hover {
		border: 1px solid;
		border-image: linear-gradient(90deg, $dark_2 1%, $light_1 100%);
		border-image-slice: 1; } }
.top-half {
	height: 180px;
	width: 100%;
	background-size: cover;
	background-position: center; }
.bot-half {
	min-height: 160px;
	height: auto;
	width: 100%;
	background-image: linear-gradient(90deg, $dark_1 1%, $light_1 100%); }
.news-name {
	font-size: 24px;
	font-weight: 400;
	color: $white;
	padding: 20px 20px 0px 20px;
	width: 100%;
	line-height: 25px;
	height: 70px;
	overflow: hidden;
	@media (max-width: 768px) {
		width: 100%; } }
.news-perex {
	font-weight: 200;
	line-height: 18px; }
.news-perex, .news-date {
	font-size: 14px;
	color: $white;
	padding: 0px 20px;
	display: block;
	height: 38px;
	overflow: hidden;
	word-break: break-word; }
.news-date {
	padding-top: 10px;
	color: $text_font !important;
	/*display: inline-block*/
	display: none; }
.news-more {
	$size: 40px;
	height: $size;
	width: $size * 2;
	border-top-left-radius: $size * 2;
	border-top-right-radius: $size * 2;
	background-color: white;
	display: inline-block;
	text-align: center;
	position: absolute;
	padding-top: 11px;
	bottom: 0;
	right: 20px;
	color: $text_font;
	font-family: $font1;
	&:hover {
		text-decoration: none; } }

.moreblog {
	border: 1px solid;
	border-image: linear-gradient(90deg, $dark_2 1%, $light_2 100%);
	border-image-slice: 1;
	text-align: center;
	color: $text_font;
	margin-top: 15px;
	p {
		font-size: 24px;
		margin-top: 20px;
		margin-bottom: 0; }
	span {
		font-size: 16px;
		font-weight: 200;
		margin-bottom: 0; } }

.btn-big {
	width: 256px;
	height: 70px;
	font-family: $font1;
	background-image: linear-gradient(90deg, $dark_2 1%, $light_2 100%);
	color: $white;
	display: block;
	margin: 15px auto 25px auto;
	text-align: center;
	font-size: 22px;
	padding-top: 15px;
	border: 1px solid transparent;
	&:hover,
	&:focus,
	&:active {
		text-decoration: none; }
	@media (max-width: 991px) {
		width: auto; } }
.btn-sm {
	width: 180px;
	height: 60px;
	font-size: 16px;
	padding-top: 5px;
	margin: 0;
	border-radius: 0px;
	margin: 0 auto; }
/* VYHODY */

.vyhody {
	margin-top: 65px;
	background-image: linear-gradient($angle-vyhody, $dark_2 1%, $light_2 100%);
	min-height: 800px;
	height: auto;
	width: 100%;
	padding-top: 50px;
	font-family: $font1;
	.vyhody-sub {
		color: $white;
		font-size: 14px;
		text-align: center;
		font-weight: 200;
		line-height: 16px;
		margin-bottom: 25px; }
	.vyhoda {
		margin-top: 25px;
		height: 250px;
		display: inline-block;
		img {
			display: block;
			margin: 0 auto;
			width: 80%;
			max-height: 115px; }
		p {
			font-size: 20px;
			text-align: center;
			font-weight: 300;
			color: $white;
			line-height: 22px;
			padding-top: 25px;
			height: 92px;
			overflow: hidden; } } }

/* PARTNERI */

.partneri {
	min-height: 600px;
	height: auto;
	width: 100%;
	font-family: $font1;
	img {
		/* TEMP STYLE - ONE IMAGE */
		width: 100%;
		height: auto;
		display: block;
		/* CORRECT STYLE - MULTIPLE IMAGES */
		//width: 16.5%
		//height:
 }		//display: inline-block
	@media (max-width: 991px) {
		min-height: auto; } }


.partneri-content {
	padding-top: 25px;
	padding-bottom: 100px;
	@media (max-width: 991px) {
		padding-bottom: 25px; } }

/* KONTAKT */

.kontakty {
	padding-bottom: 50px;
	position: relative; }

.kontakt {
	background-image: url("../images/kontakt_bottom_s.jpg");
	//background-image: url("../images/kontakt-bg.jpg")
	background-size: cover;
	background-position: center;
	//height: 860px
	height: auto;
	padding-top: 75px;
	font-family: $font1;
	@media (max-width: 991px) {
		height: auto;
		padding-top: 25px; } }

.field {
	padding-top: 20px; }

.checkbox-inline {
	position: absolute; }

.contact-form-input, .contact-form-textarea {
	border: 1px solid;
	border-image: linear-gradient(90deg, $light_2 1%, $dark_2 100%);
	border-image-slice: 1;
	width: 100%;
	background-color: white;
	font-family: $font1;
	font-size: 20px;
	font-weight: 200;
	color: $text_font; }

.contact-form-input {
	height: 65px;
	padding-left: 20px; }

.contact-form-textarea {
	padding-left: 20px;
	padding-top: 20px;
	height: 185px;
	resize: none; }

.contact-checkbox {
	display: inline-block;
	position: absolute;
	height: 15px;
	width: 15px;
	border: 1px solid;
	border-image: linear-gradient($angle-vyhody, $dark_2 1%, $light_1 100%);
	border-image-slice: 1;
	background-color: $white; }
.contact-checkbox-state2 {
	background-image: linear-gradient($angle-vyhody, $dark_1 1%, $light_1 100%);
	background-size: 60% 70%;
	background-repeat: no-repeat;
	background-position: center; }
.kontakt-podmienky {
	padding-top: 15px;
	font-family: $font1;
	font-weight: 200 !important;
	font-style: italic;
	color: $text_font;
	input {
		display: inline-block; }
	p {
		display: inline-block;
		width: 95%;
		padding-left: 45px; } }
.map {
	width: 100%;
	height: 400px;
	iframe {
		width: 100%;
		height: 100%; } }
.nove {
	position: absolute;
	margin: 1rem;
	background-color: red;
	padding: 0.2rem 0.7rem;
	color: white;
	text-transform: uppercase;
	font-weight: 700;
	border-radius: 0.5rem 0.5rem 0rem 0.5rem; }
