.person {
	font-family: $font1;
	border: 1px solid;
	border-image: linear-gradient(90deg, $dark_2 1%, $light_1 100%);
	border-image-slice: 1;
	margin-bottom: 15px;
	a, p {
		overflow-wrap: break-word;
		display: block;
		text-align: center;
		font-size: 16px;
		line-height: 16px; }
	a {
		color: $dark_1;
		&:hover, &:focus, &:active {
			color: $dark_2; } }
	p {
		&:first-child {
			font-size: 20px; } } }
.avatar {
	//max-height: 150px
	width: auto;
	display: block;
	margin: 25px auto; }
