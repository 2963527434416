.footer-part1 {
	background-image: linear-gradient(90deg, $dark_1 1%, $light_1 100%);
	width: 100%;
	height: 245px;
	max-height: 245px;
	display: block; }
.footer-img {
	height: 135px;
	width: auto;
	display: block;
	margin: 55px auto; }
.footer-content {
	font-family: $font1;
	margin-top: 45px;
	margin-bottom: 45px;
	height: 155px;
	overflow: hidden;
	color: $white;
	p {
		margin: 0; }
	p:first-child {
		font-size: 15px;
		font-weight: 200;
		line-height: 15px;
		margin-bottom: 12px;
		strong {
			font-size: 20px;
			line-height: 20px;
			font-weight: 700; } }
	p:nth-child(2) {
		margin-bottom: 20px; }
	p:not(:first-child) {
		strong {
			font-size: 15px;
			line-height: 15px;
			font-weight: 700; } } }

.footer-part2 {
	background-image: linear-gradient(90deg, $dark_2 1%, $light_1 100%);
	width: 100%;
	height: auto;
	min-height: 415px;
	display: block;
	a {
		&:hover {
			font-weight: 500 !important; } }
	@media (max-width: 991px) {
		max-height: initial;
		height: auto; } }
.footer-img-part2 {
	width: 215px;
	height: 130px;
	margin: 130px auto;
	display: block;
	@media (max-width: 991px) {
		margin: 20px auto; } }
.footer-content-part2 {
	padding-top: 80px;
	font-family: $font1;
	@media (max-width: 991px) {
		padding-top: 10px; }
	a {
		display: block;
		color: $white;
		font-weight: 200;
		font-size: 13px;
		margin-bottom: 15px;
		&:hover {
			text-decoration: none; } }
	p {
		color: $white;
		font-size: 15px;
		font-weight: 200;
		margin-bottom: 15px;
		a {
			display: inline-block;
			margin-bottom: 0px; } } }
.p2-heading {
	font-weight: 400;
	font-size: 17px;
	color: $white;
	&:after {
		display: block;
		content: "";
		width: 45px;
		height: 3px;
		margin-top: 10px;
		margin-bottom: 32px;
		background-image: linear-gradient(90deg, $dark_1 1%, $light_1 100%); } }
.footer-main {
	text-align: center;
	padding-bottom: 25px;
	padding-left: 0px;
	li {
		display: inline-block;
		font-weight: 300;
		color: white;
		a {
			color: white;
			font-weight: 700;
			&:hover, &:active, &:focus {
				font-weight: 700 !important; }
			img {
				height: 24px;
				width: auto; } } } }




