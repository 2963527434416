@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.sluzba-item {
	height: 185px;
	width: 100%;
	position: relative;
	background-color: $sluzbabg;
	margin-bottom: 15px;
	display: flex;
	flex-wrap: wrap;
	@media (max-width: 991px) {
		height: auto;
		padding-bottom: 20px;
		padding-top: 20px; } }

.sluzba-nadpis {
	align-self: center;
	p, a, p > a {
		font-size: 32px;
		color: $menu_font;
		font-weight: 400;
		text-align: right;
		line-height: 35px; }
	&:after {
		content: "";
		display: block;
		border: 2px solid;
		border-image: linear-gradient(90deg, $dark_2 1%, $light_1 100%);
		border-image-slice: 1; }
	a {
		&:hover, &:active, &:focus {
			color: $light_1; } }
	@media (max-width: 991px) {
		p {
			text-align: center; } } }
.sluzba-popis {
	align-self: center;
	p, a, p > a {
		font-size: 16px;
		font-weight: 200;
		color: $sluzbafont !important; } }

.related-articles {
	margin-bottom: 15px;
	position: relative; }

.services-left-menu {
	position: relative;
	display: block;
	width: 90%;
	@media (max-width: 991px) {
		width: 100%;
		ul {
			display: none; }
		img {
			margin: 10px auto;
			display: block;
			cursor: pointer; }
		a {
			text-align: center; } }
	ul {
		list-style: none;
		padding-left: 0; }
	li {
		background-image: linear-gradient($angle_main, $dark_1 1%, $light_1 100%);
		//padding: 15px 20px
		padding: 15px 5px 15px 10px;
		margin-bottom: 4px;
		//width: 250px
		width: 100%; }
	a {
		color: white;
		display: block;
		width: 100%;
		&:hover,
		&:active,
		&:focus {
			text-decoration: none; } } }
.left-menu-image {
	max-height: 50px;
	max-width: 50px;
	margin-bottom: 4px; }

#sluzby {
	padding: 0; }

/* SLICK UPDATE */

.prev-control {
	&:after {
		content: "";
		background-image: url("../images/svg/left-arrow.svg");
		width: 32px;
		height: 32px;
		display: block;
		cursor: pointer; }
	&:hover {
		&:after {
			background-image: url("../images/svg/left-arrow.svg"); } } }
.next-control {
	&:after {
		content: "";
		background-image: url("../images/svg/right-arrow.svg");
		width: 32px;
		height: 32px;
		display: block;
		cursor: pointer; }
	&:hover {
		&:after {
			background-image: url("../images/svg/right-arrow.svg"); } } }

.controlslick-left, .controlslick-right {
	cursor: pointer; }

.controlslick-left {
	display: block;
	position: absolute;
	left: 20px;
	top: 45%;
	z-index: 10; }
.controlslick-right {
	display: block;
	position: absolute;
	right: 20px;
	top: 45%;
	z-index: 10; }
