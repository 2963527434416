.main-menu {
	width: 100%; }
.logo-menu {
	text-align: right;
	img {
		max-height: 128px;
		height: auto;
		margin-top: 13px; } }
.search-row {
	display: inline-block;
	position: relative;
	top: -10px; }
.search-input {
	border: 1px solid transparent;
	width: 50%; }
.search-icon {
	color: $text_font; }
.navbar-default {
	box-shadow: none;
	background-image: none !important;
	background-color: $white !important;
	border-color: transparent !important;
	height: 158px; }
.navbar-nav {
	margin-top: 50px; }
.navbar-default .navbar-nav > li > a, .navbar-default .navbar-text {
	font-family: $font1;
	color: $menu_font;
	text-align: center !important; }
.navbar-default .navbar-nav > li {
	margin-left: 30px;
	text-align: center;
	border: 1px solid transparent;
	&:hover {
		background-color: transparent;
		border: 1px solid;
		border-image: linear-gradient(180deg, $light_1 1%, $dark_1 100%);
		border-image-slice: 1; } }
.navbar-default .navbar-nav > .active > a {
	background-image: none;
	background-color: $white;
	box-shadow: none;
	border: 1px solid;
	border-image: linear-gradient(180deg, $light_1 1%, $dark_1 100%);
	border-image-slice: 1;
	&:hover {
		background-color: transparent;
		border: 1px solid transparent; } }
.navbar {
	margin-bottom: 0px !important; }
.social-menu {
	width: auto;
	padding-left: 15px;
	padding-bottom: 0px;
	padding-top: 5px;
	margin-top: 55px;
	//border: 1px solid
	//border-image: linear-gradient(45deg, $dark_2 1%, $light_1 100%)
	//border-image-slice: 1
	display: inline-block;
	a:last-child {
		padding-right: 15px; }
	@media (max-width: 991px) {
		margin-top: 10px;
		margin-bottom: 10px; } }
.social-icon {
	&:after {
		width: 32px;
		height: 32px;
		background-position: center; } }

.social-insta, .social-fb, .social-twitter, .social-search {
	&:hover {
		&:after {
			content: "";
			display: inline-block; } }
	&:after {
		content: "";
		display: inline-block; } }

.social-search {
	&:hover {
		&:after {
			background-image: url("../images/svg/search.svg"); } }
	&:after {
		background-image: url("../images/svg/search.svg");
		background-size: 70%;
		background-repeat: no-repeat;
		background-position: left center; } }

.social-insta {
	&:hover {
		&:after {
			background-image: url("../images/svg/social_insta_onover.svg"); } }
	&:after {
		background-image: url("../images/svg/social_insta.svg"); } }

.social-fb {
	&:hover {
		&:after {
			background-image: url("../images/svg/social_facebook_onover.svg"); } }
	&:after {
		background-image: url("../images/svg/social_facebook.svg"); } }

.social-twitter {
	&:hover {
		&:after {
			background-image: url("../images/svg/social_twitter_onover.svg"); } }
	&:after {
		background-image: url("../images/svg/social_twitter.svg"); } }

.cofi-menu {
	padding-bottom: 5px;
	> li {
		> a {
			padding: 18px 20px 18px 20px !important; } }
	ul {
		padding-left: 0; }
	li {
		display: block;
		color: white;
		a {
			color: white;
			//line-height: 2.7
			padding: 18px 10px 18px 20px; } }
	> li {
		&:after {
			display: block;
			content: "";
			width: 100%;
			height: $sub-top;
			position: absolute; }
		> ul {
			> li {
				a {
					font-weight: 300;
					width: 100%;
					display: block; }
				&:hover {
					color: $subhover;
					> ul {
						display: block;
						background-color: $sub2;
						position: absolute;
						left: $sirka;
						//top: 0px
						margin-top: -57.5px; } }
				> a {
					color: white;
					&:hover {
						color: $subhover; }
					&:after {
						content: "+";
						display: inline-block;
						margin-left: 10px; } }
				> ul {
					> li {
						> a {
							&:hover {
								color: $subhover; } } } } } }
		ul {
			width: $sirka; }
		> ul {
			li {
				//margin: 10px 10px 10px 20px
				width: auto;
				text-align: left;
				a {
					width: 100%; } }
			display: none;
			> li {
				> ul {
					display: none; } } } }
	> li {
		> ul {
			z-index: 999; } }

	> li:nth-child(2) {
		> a {
			&:after {
				content: "";
				background-image: url("../images/svg/arrow_menu_select.svg");
				background-position: center center;
				background-size: cover;
				width: 8px;
				height: 8px;
				display: inline-block;
				margin-left: 10px; } }
		&:hover {
			> ul {
				margin-top: $sub-top;
				display: block;
				background-color: $sub1;
				position: absolute; } } } }

@media (max-width: 991px) {
	.cofi-menu {
		> li:nth-child(2) {
			> a {
				&:after {
					background-image: none; } }
			&:hover {
				> ul {
					display: none; } } } } }




@media (min-width: 991px) and (max-width: 1478px) {
	.search-row {
		top: 40px; } }

@media (min-width: 991px) and (max-width: 1300px) {
	.navbar-default .navbar-nav > li {
		margin-left: 10px; }
	.social-menu {
		position: relative;
		top: 40px; } }

@media (min-width: 768px) and (max-width: 991px) {
	.navbar-collapse.collapse {
		display: none !important; }
	.navbar-collapse.collapse.in {
		display: block !important; }
	.navbar-header .collapse, .navbar-toggle {
		display: block !important; }
	.navbar-header {
		float: none; } }

@media (max-width: 991px) {
	.collapse.in, .collapsing {
		position: absolute;
		width: 100%;
		left: 0;
		z-index: 99999; }
	.search-row {
		top: 0; }
	.navbar-collapse {
		background-color: white;
		border-color: transparent !important; }
	.navbar-default {
		height: 70px; }
	.navbar-nav {
		margin-top: 10px;
		li {
			margin-bottom: 5px; } }
	.navbar-default .navbar-nav {
		> li {
			margin-left: 15px;
			margin-right: 15px; } }
	.navbar-toggle {
		position: absolute;
		top: 8px;
		right: 0; }
	.logo-menu {
		text-align: initial;
		img {
			height: 50px;
			margin-top: 10px; } } }
