$primaryColor: #005eb2;		//tmavo modra
$secondaryColor: #05ca00;	//zelena pre hvoer
$tertiaryColor: #ffffff;		//biela
$quaternaryColor: red;	//
$pentiaryColor: green;		//

$gray: #c0c0c0;

$errorColor: #cc0000;
$warningColor: #ffcc00;
$successColor: #33cc00;

$body-font-family: 'Teko', sans-serif;
$base-font-size: 1rem;
$base-line-height: 1.5rem;
$gutter: 15px;

/* COFI VARIABLES */

/* FONTS */
$font1: 'Kanit', sans-serif;
$font2: 'Ubuntu', sans-serif;

/* COLORS */

$dark_1: #4396c0;
$light_1: #4bc1ba;

//$dark_2: #764fa0
//$light_2: #5d69b0

$dark_2: #b74b7c;
$light_2: #fecb6c;

$white: #ffffff;
$menu_font: #555f75;
$text_font: #555f75;

$pagination_color: #b8b8b8;

$header_main_color: #42b0be;

$sluzbabg: #f7f7f7;
$sluzbafont: #6e83a1;

/* MENU - COLORS */

$sub1: #46a4be;
$sub2: #4cc1b9;
$subhover: #555f75;
$subbase: white;
$sirka: 240px;
$sub-top: 5px;



/* ANGLES */

$angle_main: 115deg;
$angle-rovno: 90deg;
$angle-vyhody: 45deg;

