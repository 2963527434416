.topbar {
	background: linear-gradient($angle-rovno, $dark_2, $light_1);
	height: 5px;
	position: relative; }
@media (min-width: 991px) {
	.top-right {
		width: auto;
		position: absolute;
		right: 15px;
		top: 0; } }

.onas {
	table {
		width: 100%; }
	tr {
		position: relative;
		margin-bottom: 10px;
		//display: grid
		background-color: $sluzbabg;
		//display: flex
		//flex-wrap: wrap
		display: table;
		&:not(:first-child) {
			td {
				&:first-child {
					width: 25%; }
				&:last-child {
					width: 75%; }
				@media (max-width: 480px) {
					&:first-child {
						width: 100%; }
					&:last-child {
						width: 100%; } } } } }

	td {
		//align-self: center
		padding: 15px;
		font-size: 16px;
		font-weight: 200;
		color: $sluzbafont; }
	h2 {
		width: 100%;
		align-self: center;
		display: inline-block;
		align-self: center;
		font-size: 32px;
		color: $menu_font;
		font-weight: 400;
		text-align: right;
		line-height: 50px;
		margin-top: 0px !important;
		@media (max-width: 480px) {
			text-align: center; }
		&:after {
			content: "";
			display: block;
			border: 2px solid;
			border-image: linear-gradient(90deg, $dark_2 1%, $light_1 100%);
			border-image-slice: 1; } }
	img {
		@media (max-width: 480px) {
			max-height: 200px !important;
			width: auto; } }
	p {
		//max-width: 70%
		display: inline-block; } }
