@CHARSET 'UTF-8' {}

@import 'variables';
@import 'mixins';


[class^="fi-"]::before,
[class*=" fi-"]::before {
    margin-right: 0;
    margin-left: 0; }


@import 'master';


.content-web {
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 30px;
	font-size: 16px;
	font-family: 'Roboto Condensed';
	img {
		max-width: 100%; } }


.sm-simple {
	border: 0 none;
	background: none;
	@include box-shadow('none'); }

@media (min-width: 768px) {
	#main-menu > ul > li {
		float: none;
		display: table-cell;
		width: 1%;
		text-align: center; } }


.main-nav {
	position: relative;
	top: 0;
	left: 0;
	z-index: 1000;
	padding-top: 15px;
	padding-bottom: 15px;
	width: 100%;
	background-color: $tertiaryColor;
	&:after {
		clear: both;
		content: "\00a0";
		display: block;
		height: 0;
		font: 0px/0 serif;
		overflow: hidden; } }

.nav-brand {
	float: left;
	margin: 0;
	a {
		display: block;
		padding: 11px 11px 11px 20px;
		color: #555;
		font-size: 20px;
		font-weight: normal;
		line-height: 17px;
		text-decoration: none;
		height: 60px;
		img {
			height: 100%;
			width: auto; } } }
@media (min-width: 768px) {
	.nav-brand {
		a {
			height: 100px; } } }

#main-menu > ul {
	clear: both;
	border: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	display: none;
	li {
		background-color: transparentize($tertiaryColor, 0.25); }
	> li > a {
		text-transform: uppercase; } }


@media (min-width: 768px) {
	.main-nav {
		position: absolute;
		background-color: transparent; }
	#main-menu > ul {
		float: right;
		clear: none;
		display: block;
		> li > a > .sub-arrow {
			display: none; } } }


/* Mobile menu toggle button */

.main-menu-btn {
	float: right;
	margin: 5px 10px;
	position: relative;
	display: inline-block;
	width: 29px;
	height: 29px;
	text-indent: 29px;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); }


/* hamburger icon */

.main-menu-btn-icon,
.main-menu-btn-icon:before,
.main-menu-btn-icon:after {
	position: absolute;
	top: 50%;
	left: 2px;
	height: 2px;
	width: 24px;
	background: #555;
	-webkit-transition: all 0.25s;
	transition: all 0.25s; }

.main-menu-btn-icon:before {
	content: '';
	top: -7px;
	left: 0; }

.main-menu-btn-icon:after {
	content: '';
	top: 7px;
	left: 0; }

/* x icon */

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon {
	height: 0;
	background: transparent; }

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before {
	top: 0;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg); }

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after {
	top: 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg); }

/* hide menu state checkbox (keep it visible to screen readers) */

#main-menu-state {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px); }

/* hide the menu in mobile view */

#main-menu-state:not(:checked) ~ #main-menu {
	display: none; }

#main-menu-state:checked ~ #main-menu {
	display: block; }

@media (max-width: 768px) {
	.mobile-hide {
		display: none !important; }
	.mobile-center {
		text-align: center !important; }
	.mobile-offset {
		margin-top: 100px !important; }
	.mobile-background {
		background-position: 20% center !important; } }


@media (min-width: 768px) {
	/* hide the button in desktop view */
	.main-menu-btn {
		position: absolute;
		top: -99999px; }
	/* always show the menu in desktop view */
	#main-menu-state:not(:checked) ~ #main-menu {
		display: block; } }












ul#lang {
	list-style: none;
	margin: 0;
	*zoom: 1;
	li {
		float: left;
		visibility: collapse;
		a {
			display: block;
			padding: 3px 10px;
			visibility: visible; } }
	&:before,
	&:after {
		content: ' ';
		display: table; }
	&:after {
		clear: both; }
	&.flag {
		a {
			width: 2rem;
			height: 1.5rem;
			background-size: contain;
			background-position: 50%;
			background-repeat: no-repeat; } }
	&.code {
		a {
			&:before {
				content: attr(data-code);
				text-transform: uppercase; } } }
	&.name {
		a {
			&:before {
				content: attr(title); } } } }



.relative {
	position: relative; }

.align-center {
	text-align: center; }
.middle {
	@include verticalAlign(); }


.bold {
	font-weight: bold; }
.uppercase {
	text-transform: uppercase; }


/* line break after element */
.br {
	&:after {
		content: '\A';
		white-space: pre; } }

/* clear-fix */
.cf {
	*zoom: 1;
	&:before,
	&:after {
		content: ' ';
		display: table; }
	&:after {
		clear: both; } }

@media screen and (max-width: 1024px) {
	.cf-medium {
		*zoom: 1;
		&:before,
		&:after {
			content: ' ';
			display: table; }
		&:after {
			clear: both; } } }
@media screen and (min-width: 1025px) {
	.small-0 {
		width: 0; } }


/* proportional height */
#testProportional {
	margin: 50px auto;
	width: 300px;
	background-color: green; }

.crust {
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;
	&:before {
		content: '';
		display: block;
		padding-top: 100%; }
	.core {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		text-align: center;
		img,
		.content {
			max-width: 100%;
			max-height: 100%;
			height: auto;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto; }
		.content {
			width: 100%;
			height: 100%; } }
	&.cover .core img {
		/*width: 100%*/
		/*height: auto*/
		/*max-height: none*/
		min-width: 100%;
		min-height: 100%;
		height: auto;
		max-width: none; }
	&.ratio-2_1:before {
		padding-top: 50%; }
	&.ratio-4_3:before {
		padding-top: 75%; }
	&.ratio-16_9:before {
		padding-top: 56.25%; }
	&.ratio-9_4:before {
		padding-top: 44.44%; }
	&.ratio-5_4:before {
		padding-top: 80%; }
	&.ratio-1_2:before {
		padding-top: 200%; }
	&.ratio-4_5:before {
		padding-top: 125%; } }

/* icon before content */
a.icon-prev {
	&:hover {
		color: lighten($primaryColor, 20); } }
.icon-prev {
	position: relative;
	display: inline;
	margin-left: 2.25em;
	white-space: nowrap;
	&:before {
		position: absolute;
		margin-top: 0.15em;
		left: -1.20em;
		font-size: 1.10em;
		color: $quaternaryColor; }
	&:first-child {
		margin-left: 1.35em; } }





/* title after icon */
.tai {
	position: relative;
	white-space: nowrap;
	color: $primaryColor;
	font-size: $base-font-size;
	line-height: $base-line-height;
	margin-right: $base-font-size;
	&:after {
		content: attr(data-title);
		position: relative;
		display: inline-block;
		font-size: $base-font-size * 0.75;
		line-height: $base-line-height;
		margin-left: $base-font-size * 0.33; }
	&:before {
		line-height: $base-line-height; } }


@media screen and (max-width: 991px) {

	.tai {
		cursor: help;
		font-size: $base-font-size * 2.5;
		&:after {
			display: none; }
		&:hover:after,
		&:focus:after,
		&:active:after {
			display: block;
			position: absolute;
			top: $base-line-height * 0.5;
			left: 100%;
			z-index: 1000;
			background-color: transparentize($tertiaryColor, 0.15);
			font-size: $base-font-size;
			line-height: $base-line-height;
			padding: 1px 4px;
			@include border-radius(2px); }
		&:before {
			line-height: $base-line-height * 2.5; } }
	a.tai {
		cursor: pointer; } }


.req-sign {
	color: $errorColor;
	font-weight: bold; }
label {
	.req::after {
		@extend .req-sign;
		content: '*';
		position: absolute;
		margin-left: 0.3rem {
	    margin-right: 1.2rem; } } }


ul.evenly {
	text-align: justify;
	min-width: 500px;
	&:after {
		content: '';
		display: inline-block;
		width: 100%; }
	li {
		display: inline-block; } }

.clickable {
	cursor: zoom-in; }


/* ADMIN: edit text on the page */
.admin-edit {
	position: relative;
	//border: 1px dashed transparentize($warningColor, 0.75)
	//background-color: transparentize(white, 0.95)
	overflow: hidden;
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		border: 1px solid transparentize(darken($warningColor, 30), 0.75);
		background-color: transparentize(black, 0.95);
		&:hover {
			display: none; } }
	&:hover {
		border: 1px solid darken($errorColor, 10);
		background-color: transparentize(white, 0.33);
		color: darken($errorColor, 20);
		cursor: url(../images/pencil-red-16.gif) 0 16, pointer; }
	&[data-variant="static_content"] {
		display: inherit;
		&:hover {
			cursor: url(../images/doc-edit-16.gif) 0 16, pointer; } } }
.menu-content-edit {
	@extend .admin-edit;
	display: inherit;
	&:hover {
		cursor: url(../images/doc-edit-16.gif) 0 16, pointer; } }




/* PRVKY */

a, p, span, h1, h2, h3, h4, h5, h6, div, input, textarea, b, strong, table {
	font-family: $font1; }
a {
	outline: none;
	&:hover, &:focus, &:active {
		text-decoration: none;
		outline: none; } }
.centruj {
	float: none;
	display: block;
	margin: 0 auto; }
.f-right {
	float: right; }
@media (max-width: 991px) {
	.f-right {
		float: none; } }
.nopad {
	padding: 0 !important;
	margin: 0 !important; }
.nopad-side {
	margin-left: 0 !important;
	margin-right: 0 !important;
	padding: 0 !important; }

.btn-main {
	width: 230px;
	height: 60px;
	font-family: $font1;
	font-size: 16px;
	text-align: center;
	padding-top: 18px;
	background-color: transparent;
	color: $text_font;
	border: 2px solid;
	border-image: linear-gradient(180deg, $light_1 1%, $dark_1 100%);
	border-image-slice: 1;
	display: inline-block;
	margin-bottom: 5px;
	&:hover {
		background-image: linear-gradient(90deg, $dark_1 1%, $light_1 100%);
		border: 2px solid;
		border-image: linear-gradient(90deg, $dark_1 1%, $light_1 100%);
		border-image-slice: 1;
		text-decoration: none;
		color: $white; }
	@media (max-width: 1800px) {
		width: 49%; }
	@media (max-width: 400px) {
		font-size: 14px; } }

.heading-type1 {
	font-size: 35px;
	font-family: $font1;
	color: $text_font;
	text-align: center;
	&:after {
		content: "";
		display: block;
		margin: 15px auto;
		width: 170px;
		border-bottom: 2px solid;
		border-image: linear-gradient(90deg, $dark_2 1%, $light_1 100%);
		border-image-slice: 1; } }

.heading-type2 {
	font-size: 35px;
	font-family: $font1;
	color: $white;
	text-align: center;
	&:after {
		content: "";
		display: block;
		margin: 15px auto;
		width: 170px;
		border-bottom: 2px solid;
		border-image: linear-gradient(90deg, $white 1%, $white 100%);
		border-image-slice: 1; } }

.customflex {
	display: flex;
	flex-wrap: wrap; }
.customflex-item {
	align-self: center; }
.topoffset {
	margin-top: 50px !important; }

/* STRANKOVANIE */

.pagination {
	padding-top: 20px;
	display: table;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	a {
		border-radius: 0px; }
	li {
		border-radius: 0px !important;
		height: 35px;
		min-width: 35px !important;
		padding: 5px !important;
		a {
			color: $pagination_color;
			&:hover, &:focus {
				background-image: linear-gradient(90deg, $dark_1 1%, $light_1 100%);
				color: white; } }
		&:first-child {
			a, span {
				border-top-left-radius: 0px;
				border-bottom-left-radius: 0px;
				border: 1px solid #ddd; } }
		&:last-child {
			a, span {
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px; } }
		span {
			min-width: 35px !important;
			text-align: center; } }
	.current {
		padding: 0;
		background-color: white;
		span {
			border: 1px solid #ddd;
			background-image: linear-gradient(90deg, $dark_1 1%, $light_1 100%);
			color: white;
			&:hover {
				color: white; } } } }

/* VYHLADAVANIE */

.search {
	cursor: pointer; }
.search-open {
	margin-right: 25px; }

#search-container {
	display: none;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 999;
	background-color: rgba(255,255,255,0.8);
	top: 0;
	> div {
		margin: 0 auto; }
	button {
		color: #000;
		margin-left: 25px;
		margin-top: 10px; } }
#search_keyword {
	border: 0;
	color: #000;
	float: left;
	font-size: 24px;
	background-color: transparent;
	width: 500px;
	padding: 15px;
	outline: none;
	transition: all 0.4s ease;
	border-bottom: 3px solid #000;
	-webkit-transition: all 0.4s ease;
	padding-right: 44px;
	@media screen and (max-width: 768px) {
		width: 75%;
		margin: 0 auto; } }

.search-close {
	color: #000;
	position: absolute;
	top: 3rem;
	right: 4rem; }
.blur {
	filter: blur(5px); }
.showflex {
	display: flex !important; }
.noscroll {
	overflow: hidden !important; }
.cursor {
	cursor: pointer; }
.simpleflex-item {
	align-self: center; }


@import 'footer';

@import 'modules/index';
@import 'modules/menu';
@import 'modules/contact';
@import 'modules/home';
@import 'modules/article';
@import 'modules/sluzby';
@import 'modules/footer';
@import 'default_javascript';
